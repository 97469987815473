@use "../../styles/colors" as *;
@use "../../styles/mixins" as *;
@use "../../styles//variables" as *;

.background {
  background-color: $primary-color-background;
}

.about {
  padding: 1rem;

  @include tablet {
    padding: 2rem;
  }

  @include desktop {
    padding: 3rem;
    max-width: 80rem;
    margin: 0 auto;
  }

  &__title {
    text-transform: uppercase;
    padding-bottom: 1rem;

    @include tablet {
      padding-bottom: 2rem;
    }
  }
}

.skills {
  padding: 1rem;

  @include tablet {
    padding: 2rem;
  }

  @include desktop {
    padding: 3rem;
    margin: 0 auto;
    max-width: 80rem;
  }

  &__wrapper-bck {
    background-color: $secondary-color-details;
  }

  &__item {
    font-size: 0.8125rem;
    line-height: 1.25rem;
  }
  &__header {
    padding-bottom: 1rem;

    @include tablet {
      padding-bottom: 2em;
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    @include tablet {
      flex-direction: row;
      align-items: center;
      gap: 2rem;
    }

    @include desktop {
      gap: 3rem;
      max-width: 78.625rem;
      margin: 0 auto;
    }
  }
  &__content {
    padding: 1rem;

    @include cards;
    width: 100%;
    height: 294px;

    @include tablet {
      margin-bottom: 0;
      width: 13.75rem;
    }

    @include desktop {
      width: 18.75rem;
      height: 18.75rem;
    }
  }
  &__title {
    text-transform: uppercase;
    color: $primary-color-background;
  }

  &__content-header {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__icon {
    height: 65px;
  }

  &__subtitle {
    padding-top: 1rem;
    font-size: 1.25rem;
    color: $secondary-color-details;
  }

  &__list {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem 0;
  }
}

.projects {
  padding: 1rem;

  @include tablet {
    padding: 2rem;
  }

  @include desktop {
    padding: 3rem;
    max-width: 80rem;
    margin: 0 auto;
  }

  &__header {
    padding-bottom: 1rem;
    @include tablet {
      padding-bottom: 2rem;
    }
  }

  &__content {
    padding-top: 0.5rem;
  }

  &__title {
    text-transform: uppercase;
  }

  &__container {
    @include cards;
    width: 100%;
    height: 9.375rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    transition: 0.5s ease-in-out;

    &:hover {
      opacity: 0.9;
    }

    @include tablet {
      height: 180px;
    }

    @include desktop {
      width: 18.75rem;
      height: 18.75rem;
    }
  }

  &__subtitle {
    font-size: 1.25rem;
    padding-bottom: 0.5rem;
    text-align: center;
    color: $primary-color-background;
  }

  &__btn {
    text-decoration: none;
    color: $primary-color-background;
    border: 0.0625rem solid $primary-color-background;
    border-radius: 20px;
    font-size: 0.8125rem;
    width: 160px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
  }

  &__stack {
    padding-bottom: 1rem;
    color: $primary-color-background;
    @include tablet {
      padding-bottom: 2rem;
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    @include tablet {
      gap: 2rem;
    }

    @include desktop {
      flex-direction: row;
      flex-wrap: wrap;
      gap: 3rem;
      max-width: 80rem;
      margin: 0 auto;
    }
  }

  .coffeeshop {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
      url(../../assets/images/coffee-beans.jpeg);
  }

  .travelsite {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
      url(../../assets/images/hawaii.jpg);
  }

  .bandsite {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
      url(../../assets/images/bandsite.jpg);
  }

  .brainflix {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
      url(../../assets/images/brainflix.jpeg);
  }

  .instock {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
      url(../../assets/images/instock.jpeg);
  }

  .meowfinder {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
      url(../../assets/images/meowfinder.jpeg);
  }

  &__link {
    text-decoration: none;
    font-weight: 600;
    color: $secondary-color-details;
  }
}
