@use "./mixins" as *;
@use "./colors" as *;

//Typography Rules

//Mobile

//Page Header
h1 {
  font-size: 2.8125rem;
  line-height: 2rem;
  font-weight: 500;
}

//Section Header
h2 {
  font-size: 0.8125rem;
  line-height: 1.25rem;
  font-weight: 700;
}

//Subheader
h3 {
  font-size: 0.8125rem;
  line-height: 1.125rem;
  font-weight: 700;
}

body {
  p {
    font-size: 0.8125rem;
    line-height: 1.125rem;
    font-weight: 300;
  }
}
.label,
.btn {
  font-size: 0.75rem;
  line-height: 1.25rem;
  font-weight: 600;
}

.links {
  font-size: 0.8125rem;
  line-height: 1.25rem;
  font-weight: 300;
}

//Tablet & Desktop
@include tablet {
  //Page Header
  h1 {
    font-size: 3rem;
    line-height: 2.75rem;
    font-weight: 700;
  }

  //Section Header
  h2 {
    font-size: 1rem;
    line-height: 1.25rem;
    font-weight: 700;
  }

  //Subheader
  h3 {
    font-size: 0.875rem;
    line-height: 1.375rem;
    font-weight: 700;
  }

  //Body Copy
  body {
    p {
      font-size: 0.9375rem;
      line-height: 1.5rem;
      font-weight: 300;
    }
  }

  //Labels & Buttons
  .label,
  .btn {
    font-size: 0.75rem;
    line-height: 1.25rem;
    font-weight: 700;
  }

  .links {
    font-size: 1rem;
    line-height: 1.25rem;
    font-weight: 300;
  }
}
