@use "./variables" as *;
@use "./colors" as *;

//Break-points
@mixin tablet {
  @media screen and (min-width: $tablet-breakpoint) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: $desktop-breakpoint) {
    @content;
  }
}

@mixin flexed($direction, $justify, $align) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
}

@mixin links {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: $primary-color;
  text-align: center;
  text-transform: uppercase;
}

@mixin btn {
  border: none;
  text-decoration: none;
  width: 14.375rem;
  height: 2.5rem;
  background-color: $primary-color-btn;
  box-shadow: 0px 4px 4px rgba(117, 116, 111, 0.3);
  color: $primary-color-background;
  transition: 0.5s ease-in-out;
  @include flexed(row, center, center);
  border-radius: 20px;

  &:hover {
    background-color: $secondary-color-details;
  }
}

@mixin cards {
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
  background-color: $primary-color-background;
  border-radius: 0.625rem;
}
