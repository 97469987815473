@use "../../styles/colors" as *;
@use "../../styles/mixins" as *;
@use "../../styles//variables" as *;
@use "../../styles//typography" as *;

.hero {
  padding: 8rem 1rem 4rem 1rem;

  &__wrapper-bck {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
      url("../../assets//images/hero-bio.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    min-width: 100%;
    min-height: 100%;

    @include desktop {
    }
  }

  @include tablet {
    padding: 10rem 2rem 6rem 2rem;
  }

  @include desktop {
    padding: 16rem 3rem 12rem 3rem;
    margin: 0 auto;
    max-width: 80rem;
  }

  &__content {
    text-align: left;
    color: $primary-color-background;
    padding-bottom: 1.5rem;
    font-size: 1.125rem;
    line-height: 2rem;

    @include desktop {
      padding-bottom: 3.5rem;
      font-size: 1.8rem;
    }
  }

  &__title {
    line-height: 3rem;
    text-align: left;
    color: $primary-color-background;
    padding-bottom: 1.5rem;

    @include tablet {
      padding-bottom: 2.5rem;
    }

    @include desktop {
      padding-bottom: 3.5rem;
      font-size: 3.5rem;
    }
  }

  &__btn {
    @include btn;

    @include tablet {
      width: 15.625rem;
    }

    @include desktop {
      width: 18.625rem;
      font-size: 1rem;
    }
  }
}
