@use "../../styles/colors" as *;
@use "../../styles/mixins" as *;
@use "../../styles//variables" as *;

.header {
  padding: 0 1rem;

  &__wrapper-bck {
    background-color: $primary-color-neutral;
    position: fixed;
    top: 0;
    width: 100%;
    opacity: 0.85;
    z-index: 1;
  }

  @include tablet {
    padding: 0.5rem 2rem;
  }

  @include desktop {
    padding: 0.5rem 3rem;
    max-width: 80rem;
    margin: 0 auto;
  }
}

.nav {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__img {
    width: 7.5rem;
  }
  &__list {
    display: flex;
    align-items: center;
    align-content: center;
    gap: 1rem;
  }

  &__link {
    @include links;
    padding: 15px 0;
    width: 100%;
    transition: 0.5s ease-in-out;

    &:hover {
      color: $secondary-color-details;
    }
  }

  &__left {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  &__link-logo {
    font-family: "Pacifico", cursive;
    font-size: 1.25rem;
    text-decoration: none;
    color: $primary-color-btn;
    transition: 0.5s ease-in-out;

    &:hover {
      color: $secondary-color-details;
    }

    @include tablet {
      font-size: 1.875rem;
    }
  }
}
