//Primary Colors
$primary-color: #323232; //
$primary-color-background: #ffffff; //White
$primary-color-neutral: #faf9f7; //Nude
$primary-color-btn: #d98f89; //btn
$primary-color-bck: #efcdca; //btn

//Secondary Colors:
$secondary-color: #000000; //DarkBlack
$secondary-color-neutral: #e1e1e1; //Mercury
$secondary-color-background: #f7f2ef; //Alabaster
$secondary-color-details: #3b625f;

//Media Query Breakpoints:
$tablet-breakpoint: 768px;
$desktop-breakpoint: 1280px;
